import React, { useState } from "react";
import axios from "axios"; // Import axios for making API requests

import ImageUpload from "./ImageUpload";

const App = () => {
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [comment, setComment] = useState("");
  const [url, setUrl] = useState("");
  const [scenes, setScenes] = useState([]);
  const [scenesFar, setScenesFar] = useState([]);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
          setError(null);
          fetchScenes(latitude, longitude);
          fetchScenesFar(latitude, longitude);
        },
        (err) => {
          setError(err.message);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };

  const fetchScenes = async (latitude, longitude) => {
    try {
      const response = await axios.get("/api/near-me", {
        params: { latitude, longitude },
      });
      setScenes(response.data);
    } catch (error) {
      setError("Failed to fetch scenes.");
    }
  };

  const fetchScenesFar = async (latitude, longitude) => {
    try {
      const response = await axios.get("/api/not-near-me", {
        params: { latitude, longitude },
      });
      setScenesFar(response.data);
    } catch (error) {
      setError("Failed to fetch scenes.");
    }
  };

  const handleImageUpload = (url) => {
    setUrl(url);
  };

  const handleInputChange = (e) => {
    setComment(e.target.value);
  };

  const handleSubmit = async () => {
    if (!location.latitude || !location.longitude || !comment || !url) {
      setError("Please provide all required information.");
      return;
    }

    try {
      await axios.post("/api/add", {
        comment,
        url,
        latitude: location.latitude,
        longitude: location.longitude,
      });
      alert("Data submitted successfully!");
      setUrl("");
      setComment("");
      setError(null);
      fetchScenes(location.latitude, location.longitude);
    } catch (error) {
      setError("Failed to submit data.");
    }
  };

  const handleClick = (scene) => {
    setSelectedImage(scene);
  };

  const closePopup = () => {
    setSelectedImage(null);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen pb-20">
      <button
        onClick={getLocation}
        className="px-4 py-2 mt-5 text-white bg-blue-500 rounded hover:bg-blue-700"
      >
        Get Your Current Location
      </button>

      {error && <p className="mt-2 text-red-500">Error: {error}</p>}
      {location.latitude && (
        <div className="mt-5 p-4 bg-white rounded shadow-md w-3/4 text-center">
          {!url && <ImageUpload handleImageUpload={handleImageUpload} />}
          {url && (
            <div className="flex flex-col justify-center items-center pb-6">
              <img src={url} alt="Uploaded" />
              <input
                type="text"
                value={comment}
                onChange={handleInputChange}
                placeholder="Add a note..."
                className="mt-5 px-4 py-2 border rounded"
              />
              {comment && (
                <button
                  onClick={handleSubmit}
                  className="px-4 py-2 my-5 text-white bg-blue-500 rounded hover:bg-blue-700"
                >
                  Submit
                </button>
              )}
            </div>
          )}
        </div>
      )}
      <div className="flex flex-wrap justify-center pt-6">
        {scenes.map((scene) => (
          <div key={scene.id} className="p-2 w-full sm:w-1/2 md:w-1/3 lg:w-1/4">
            <img
              src={scene.url}
              alt={scene.comment}
              className="w-full h-auto object-cover"
              onClick={() => handleClick(scene)}
            />
          </div>
        ))}
        {scenesFar.map((scene) => (
          <div key={scene.id} className="p-2 w-full sm:w-1/2 md:w-1/3 lg:w-1/4">
            <p>From around the world</p>
            <img
              src={scene.url}
              alt={scene.comment}
              className="w-full h-auto object-cover"
              onClick={() => handleClick(scene)}
            />
          </div>
        ))}
        {selectedImage && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-4 rounded shadow-lg max-w-md w-3/4">
              <h2 className="text-xl font-bold">{selectedImage.comment}</h2>
              <p className="mt-2">
                Distance:{" "}
                {selectedImage.distance / 1000 < 1
                  ? "<1 km"
                  : `${(selectedImage.distance / 1000).toFixed(1)} km`}
              </p>
              <button
                className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
                onClick={closePopup}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default App;
