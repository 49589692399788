import React, { useState, useRef } from "react";
import axios from "axios";
import Pica from "pica";

export default function ImageUpload({ handleImageUpload }) {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const uploadFile = async () => {
    if (!file) return;

    const pica = Pica();
    const img = new Image();
    const objectUrl = URL.createObjectURL(file);
    img.src = objectUrl;

    img.onload = async () => {
      try {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        const maxSideLength = 640;

        let cropWidth, cropHeight, offsetX, offsetY;

        if (img.width > img.height) {
          cropHeight = img.height;
          cropWidth = img.height;
          offsetX = (img.width - img.height) / 2;
          offsetY = 0;
        } else {
          cropWidth = img.width;
          cropHeight = img.width;
          offsetX = 0;
          offsetY = (img.height - img.width) / 2;
        }

        canvas.width = maxSideLength;
        canvas.height = maxSideLength;

        context.drawImage(
          img,
          offsetX,
          offsetY,
          cropWidth,
          cropHeight,
          0,
          0,
          maxSideLength,
          maxSideLength
        );

        const result = await pica.resize(canvas, canvas);
        const blob = await pica.toBlob(result, "image/webp");

        setUploading(true);

        const params = { filename: file.name };
        const response1 = await axios.get("/api/get-upload-url", { params });
        const { url: presignedUrl, filename } = response1.data;

        if (!filename) {
          throw new Error("Filename not found.");
        }

        const response = await fetch(presignedUrl, {
          method: "PUT",
          body: blob,
          headers: { "Content-Type": file.type },
        });

        if (response.ok) {
          setFile(null);
          setUploading(false);
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
          handleImageUpload(`https://dream.img.in.net/${filename}`);
        } else {
          throw new Error("Upload failed.");
        }
      } catch (error) {
        console.error("There was an error uploading the file:", error);
        alert("There was an error uploading the file. Please try again.");
      } finally {
        URL.revokeObjectURL(objectUrl);
      }
    };

    img.onerror = () => {
      console.error("There was an error loading the image.");
      URL.revokeObjectURL(objectUrl);
    };
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} ref={fileInputRef} />
      {file && (
        <button
          type="button"
          className="bg-blue-500 rounded hover:bg-blue-700 px-4 py-2 m-4 text-lg font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
          onClick={uploadFile}
          disabled={uploading}
        >
          Upload
        </button>
      )}
      {uploading && <p>Uploading...</p>}
    </div>
  );
}
